import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import {
  CONTENT_METADATA_URL,
  COURSES_METADATA_URL,
  SAVE_CONTENT_METADATA_URL
} from '../../utils/Constants';
import { getJwtToken, langSwitch } from '../utils';

/* How to inject the authorization header once for all request? */
axios.interceptors.request.use(
  async function (config) {
    const jwtToken = await getJwtToken();
    config.headers = { Authorization: jwtToken };
    config.params = { filterFields: false };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export const audience = [
  {
    id: '2',
    code: 'gps',
    displayName: 'GPs'
  },
  {
    id: '3',
    code: 'gp-trainees',
    displayName: 'GP trainees'
  },
  {
    id: '4',
    code: 'hospital-doctors',
    displayName: 'Hospital doctors'
  },
  {
    id: '5',
    code: 'foundation-doctors',
    displayName: 'Foundation doctors'
  },
  {
    id: '7',
    code: 'practice-nurses',
    displayName: 'Practice nurses'
  },
  {
    id: '10',
    code: 'medical-students',
    displayName: 'Medical Students'
  },
  {
    id: '11',
    code: 'medical-trainees',
    displayName: 'Medical trainees'
  },
  {
    id: '12',
    code: 'nurses',
    displayName: 'Nurses'
  },
  {
    id: '13',
    code: 'specialist-nurses',
    displayName: 'Specialist nurses'
  },
  {
    id: '20',
    code: 'ahp',
    displayName: 'Allied Healthcare Professionals'
  },
  {
    id: '21',
    code: 'physician-associates',
    displayName: 'Physician associates'
  },
  {
    id: '22',
    code: 'nhs-talking-therapies-staff',
    displayName: 'NHS Talking Therapies staff'
  }
];
export const specialties = [
  { title: 'Acute and Emergency', id: '1' },
  { title: 'Allergy', id: '200' },
  { title: 'Cardiology', id: '3' },
  { title: 'Care of older people', id: '100' },
  { title: 'Core skills', id: '101' },
  { title: 'Clinical skills', id: '201' },
  { title: 'Communication skills', id: '202' },
  { title: 'Dermatology', id: '4' },
  { title: 'Diabetes', id: '5' },
  { title: 'Endocrinology', id: '203' },
  { title: 'ENT', id: '6' },
  { title: 'Gastroenterology', id: '7' },
  { title: 'General Medicine', id: '102' },
  { title: 'Haematology', id: '204' },
  { title: 'Infectious diseases', id: '10' },
  { title: "Men's health", id: '103' },
  { title: 'Mental Health', id: '17' },
  { title: 'Musculoskeletal', id: '11' },
  { title: 'Neurology', id: '13' },
  { title: 'Oncology', id: '14' },
  { title: 'Ophthalmology', id: '15' },
  { title: 'Paediatrics', id: '16' },
  { title: 'Palliative care', id: '104' },
  { title: 'Professional skills', id: '43' }, // Used to be 'Personal and Professional skills (non clinical)'
  //{ title: 'Practical skills', id: '105' }, // Superseded by 'Clinical skills', id: 201
  { title: 'Public health', id: '205' },
  { title: 'Quality improvement', id: '29' },
  { title: 'Renal/Kidney', id: '18' },
  { title: 'Respiratory', id: '19' },
  { title: 'Sexual health', id: '206' },
  { title: 'Surgery', id: '207' },
  { title: 'Women\'s health', id: '22'} // Used to be 'Women's and sexual health' e.g. live course 10062945
];
export const subscriptionsList = [
  {
    displayName: 'BMJ Learning standard',
    id: '1',
    code: 'bmj-learning-standard'
  },
  {
    displayName: 'Free',
    id: '2',
    code: 'bmj-learning-free'
  }
];
export const additionalSubscriptionsList = [
  // Journal
  { displayName: 'BMJ Learning JMG', id: '31', code: 'bmj-learning-highwire-jmg' },
  { displayName: 'BMJ Learning Journal', id: '23', code: 'bmj-learning-journal' },
  { displayName: 'BMJ Learning Quality', id: '22', code: 'bmj-learning-quality' },
  {
    displayName: 'British Journal of Sports Medicine',
    id: '11',
    code: 'bmj-learning-highwire-bjsm'
  },
  { displayName: 'Education in Heart', id: '7', code: 'bmj-learning-highwire-education-in-heart' },
  { displayName: 'Gut', id: '8', code: 'bmj-learning-highwire-gut' },
  { displayName: 'Education in heart', id: '9', code: 'bmj-learning-highwire-heart' },
  { displayName: 'Journal of Clinical Pathology', id: '10', code: 'bmj-learning-highwire-jcp' },
  // India course
  { displayName: 'BMJ Learning CKD India 1', id: '60', code: 'bmj-learning-ckd-india-1' },
  { displayName: 'BMJ Learning CKD India 2', id: '61', code: 'bmj-learning-ckd-india-2' },
  { displayName: 'BMJ Learning CKD India 3', id: '62', code: 'bmj-learning-ckd-india-3' },
  { displayName: 'BMJ Learning CKD India 4', id: '63', code: 'bmj-learning-ckd-india-4' },
  { displayName: 'BMJ Learning CKD India 5', id: '64', code: 'bmj-learning-ckd-india-5' },
  { displayName: 'BMJ Learning CKD India 6', id: '65', code: 'bmj-learning-ckd-india-6' },
  { displayName: 'BMJ Learning CKD India 7', id: '66', code: 'bmj-learning-ckd-india-7' },
  { displayName: 'BMJ Learning CKD India 8', id: '67', code: 'bmj-learning-ckd-india-8' },
  { displayName: 'BMJ Learning CKD India 9', id: '68', code: 'bmj-learning-ckd-india-9' },
  { displayName: 'BMJ Learning CKD India 10', id: '69', code: 'bmj-learning-ckd-india-10' },
  { displayName: 'BMJ Learning CKD India 11', id: '70', code: 'bmj-learning-ckd-india-11' },
  { displayName: 'BMJ Learning CKD India 12', id: '71', code: 'bmj-learning-ckd-india-12' },
  { displayName: 'BMJ Learning CKD India 13', id: '72', code: 'bmj-learning-ckd-india-13' },
  { displayName: 'BMJ Learning diabetes 1', id: '41', code: 'bmj-india-diabetes-unit-1' },
  { displayName: 'BMJ Learning diabetes 2', id: '42', code: 'bmj-india-diabetes-unit-2' },
  { displayName: 'BMJ Learning diabetes 3', id: '43', code: 'bmj-india-diabetes-unit-3' },
  { displayName: 'BMJ Learning diabetes 4', id: '44', code: 'bmj-india-diabetes-unit-4' },
  { displayName: 'BMJ Learning diabetes 5', id: '45', code: 'bmj-india-diabetes-unit-5' },
  { displayName: 'BMJ Learning diabetes 6', id: '46', code: 'bmj-india-diabetes-unit-6' },
  { displayName: 'BMJ Learning India diabetes 7', id: '89', code: 'bmj-india-diabetes-unit-7' },
  {
    displayName: 'BMJ Learning India Palliative Care 1',
    id: '75',
    code: 'bmj-learning-palliative-india-1'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 2',
    id: '76',
    code: 'bmj-learning-palliative-india-2'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 3',
    id: '77',
    code: 'bmj-learning-palliative-india-3'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 4',
    id: '78',
    code: 'bmj-learning-palliative-india-4'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 5',
    id: '79',
    code: 'bmj-learning-palliative-india-5'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 6',
    id: '80',
    code: 'bmj-learning-palliative-india-6'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 7',
    id: '81',
    code: 'bmj-learning-palliative-india-7'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 8',
    id: '82',
    code: 'bmj-learning-palliative-india-8'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 9',
    id: '83',
    code: 'bmj-learning-palliative-india-9'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 10',
    id: '84',
    code: 'bmj-learning-palliative-india-10'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 11',
    id: '85',
    code: 'bmj-learning-palliative-india-11'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 12',
    id: '86',
    code: 'bmj-learning-palliative-india-12'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 13',
    id: '87',
    code: 'bmj-learning-palliative-india-13'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 14',
    id: '88',
    code: 'bmj-learning-palliative-india-14'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 1',
    id: '99',
    code: 'bmj-learning-rheum-india-1'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 2',
    id: '100',
    code: 'bmj-learning-rheum-india-2'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 3',
    id: '101',
    code: 'bmj-learning-rheum-india-3'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 4',
    id: '102',
    code: 'bmj-learning-rheum-india-4'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 5',
    id: '103',
    code: 'bmj-learning-rheum-india-5'
  },
  {
    displayName: 'BMJ Learning Rheumatology Unit 6',
    id: '104',
    code: 'bmj-learning-rheum-india-6'
  },
  // Customer
  { displayName: 'BMJ Learning: ADB', id: '95', code: 'bp-learning-adb' },
  { displayName: 'BMJ Learning Darwin', id: '56', code: 'bmj-learning-dtra' },
  { displayName: 'BMJ Learning Elucidat Test', id: '57', code: 'bmj-learning-elucidat-test' },
  { displayName: 'BMJ Learning - RSM study day modules', id: '2', code: 'bmj-learning-rsm' },
  // Collection/topic
  {
    displayName: 'BMJ Learning Basic Life Support',
    id: '25',
    code: 'bmj-learning-basic-life-support'
  },
  {
    displayName: 'Learning: Common respiratory conditions',
    id: '94',
    code: 'learning-common-respiratory-conditions'
  },
  { displayName: 'BMJ Learning ECG Skills', id: '29', code: 'bmj-learning-ecg-skills' },
  { displayName: 'BMJ Learning Surgical Skills', id: '17', code: 'bmj-learning-surgical-skills' },
  // China
  { displayName: 'BMJL Chinese modules', id: '38', code: 'bmj-learning-chinese-modules' },
  // BMA
  { displayName: 'BMA Membership', id: '3', code: 'bmj-learning-bma-membership' },
  { displayName: 'BMA reps', id: '4', code: 'bmj-learning-bma-reps' },
  { displayName: 'BMA Staff', id: '27', code: 'bmj-learning-bma-staff' },
  { displayName: 'BMJ Learning: BMA PCN', id: '93', code: 'bmj-learning-bma-pcn' },
  { displayName: 'Imperial Research Institution', id: '98', code: 'imperial-research-institution' },
  // Rtop
  { displayName: 'Research to Publication', id: '36', code: 'bmj-learning-r2p' }
];

const emptyCollection = (payload = {}) => ({
  title: '',
  locale: 'en_GB',
  status: 'PEND',
  duration: {},
  homepageImage: '',
  objectives: [],
  category: [],
  contributors: [],
  accessResources: [
    // {
    //   displayName: 'BMA Membership',
    //   id: '3',
    //   code: 'bmj-learning-bma-membership'
    // },
    {
      displayName: 'BMJ Learning standard',
      id: '1',
      code: 'bmj-learning-standard'
    }
    // ,
    // {
    //   code: 'bmj-learning-r2p',
    //   displayName: 'Research to Publication',
    //   id: '36'
    // }
  ],
  targetAudiences: [{ displayName: 'All audiences', id: '1', code: 'all' }],
  type: 'COLLECTION',
  collectionIds: [],
  ...payload
});

const initialState = {
  data: emptyCollection(),
  courses: [],
  status: 'idle',
  serverError: false,
  subscriptionsList,
  additionalSubscriptionsList,
  additionalSubscriptions: []
};

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    SET_COLLECTION(state, { payload }) {
      state.data = payload;
    },
    HANDLE_STATUS(state, { payload }) {
      state.status = payload;
    },
    HANDLE_COURSE_STATUS(state, { payload: { key, value } }) {
      state.courses[state.courses.length - 1][key] = value;
    },
    SET_COLLECTION_PROP(state, { payload: { key, value } }) {
      state.data[key] = value;
    },
    UPDATE_SUBSCRIPTIONS(state, { payload: item }) {
      const subscriptionCopy = [...state.data.accessResources];
      const index = subscriptionCopy.findIndex((obj) => +obj.id === +item.id);
      if (index === -1) {
        const i = subscriptionsList.findIndex((o) => +o.id === +item.id);
        subscriptionCopy.push(subscriptionsList[i]);
      } else {
        subscriptionCopy.splice(index, 1);
      }
      state.data.accessResources = subscriptionCopy;
    },
    UPDATE_ADDITIONAL_SUBSCRIPTION(state, { payload = [] }) {
      const array = state.data.accessResources.filter((obj) => +obj.id < 3);
      payload.forEach((itemInPayload) => {
        array.push(itemInPayload);
      });
      state.data.accessResources = array;
    },
    SET_COURSES_METADATA(state, { payload }) {
      state.courses = [...payload];
    },
    UPDATE_LAST_COURSE_METADATA(state, { payload }) {
      state.courses[state.courses.length - 1] = payload;
    },
    ADD_COURSE(state) {
      state.courses.push({});
    },
    DELETE_COURSE_INCLUDED(state, { payload: id }) {
      const index = state.courses.findIndex(({ moduleId }) => moduleId === id);
      state.courses.splice(index, 1);
    },
    HANDLE_CHIP_SELECTION(state, { payload: { section, id, action, list } }) {
      let array = [];
      let index, isIncluded;
      if (!state.data[section]) {
        state.data[section] = [];
      }
      switch (action) {
        case 'selectAll':
          array = [...list];
          break;
        case 'deselectAll':
          array = [];
          break;
        case 'single':
          index = list.findIndex((obj) => +obj.id === +id);
          isIncluded = state.data[section]?.some((obj) => +obj.id === +list[index].id);
          if (isIncluded) {
            const i = state.data[section].findIndex((obj) => +obj.id === +id);
            array = [...state.data[section]];
            array.splice(i, 1);
          } else {
            array = [...state.data[section], list[index]];
          }
          break;
        default:
          break;
      }
      state.data[section] = array;
    },
    HANDLE_AUTHOR(state, { payload: { key, value, index } }) {
      const contributors = [...state.data.contributors];
      contributors[index][key] = value;
      state.data.contributors = contributors;
    },
    HANDLE_ADD_DELETE_AUTHOR(state, { payload: action }) {
      let contributors;
      if (action === 'add') {
        const newAuthor = {
          name: '',
          type: 'original-author',
          description: '',
          disclosure: ''
        };
        contributors = [...state.data.contributors, newAuthor];
      } else {
        contributors = [...state.data.contributors];
        contributors.pop();
      }
      state.data.contributors = contributors;
    },
    CREATE_NEW_COLLECTION(state) {
      // for now, the BE will create a new collection only if status is LIVE or ARCH(?). Pending state is not allowed.
      state.data = emptyCollection({ status: 'LIVE' });
      state.courses = [];
    },
    HANDLE_SERVER_ERR(state, { payload }) {
      state.serverError = payload;
    },
    HANDLE_DURATION(state, { payload: { key, value } }) {
      state.data.duration[key] = value;
    }
  }
});

export const {
  SET_COLLECTION,
  HANDLE_STATUS,
  HANDLE_COURSE_STATUS,
  SET_COLLECTION_PROP,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_ADDITIONAL_SUBSCRIPTION,
  SET_COURSES_METADATA,
  ADD_COURSE,
  UPDATE_LAST_COURSE_METADATA,
  DELETE_COURSE_INCLUDED,
  HANDLE_CHIP_SELECTION,
  HANDLE_AUTHOR,
  HANDLE_ADD_DELETE_AUTHOR,
  CREATE_NEW_COLLECTION,
  HANDLE_SERVER_ERR,
  HANDLE_DURATION
} = collectionSlice.actions;

export const get_collection = ({ collectionId, language = 'en_GB' }) =>
  async function (dispatch) {
    try {
      dispatch(HANDLE_STATUS('loading'));
      const url = CONTENT_METADATA_URL.replace('{id}', collectionId).replace(
        '{language}',
        language
      );
      const { data } = await axios.get(url);
      // We are removing isRtop because ATM the BE does not allow this prop to be present in order to save the collection data.
      delete data.isRtop;
      dispatch(fetch_courses({ courses: data.collectionIds, language: data.language }));
      dispatch(SET_COLLECTION(data));
      dispatch(HANDLE_STATUS('idle'));
    } catch (e) {
      dispatch(HANDLE_STATUS('error'));
    }
  };

export const fetch_courses = ({ courses = [], language = 'en-gb' }) =>
  async function (dispatch) {
    try {
      const url = `${COURSES_METADATA_URL}?moduleIds=${courses.join(',')}&language=${langSwitch(
        language
      )}`;
      const { data } = await axios.get(url);
      dispatch(SET_COURSES_METADATA(data.courses));
    } catch (err) {
      console.log(err);
    }
  };

export const fetch_course = ({ courseId = '', language = 'en-gb' }) =>
  async function (dispatch, getState) {
    try {
      /* verify if duplicate id */
      const {
        collection: { courses }
      } = getState();
      const index = courses.findIndex(({ moduleId }) => moduleId === courseId);
      if (index > -1) {
        dispatch(HANDLE_COURSE_STATUS({ key: 'isDuplicated', value: true }));
        return;
      }
      dispatch(HANDLE_COURSE_STATUS({ key: 'isFetching', value: true }));
      dispatch(HANDLE_COURSE_STATUS({ key: 'isDuplicated', value: false }));
      const url = `${COURSES_METADATA_URL}?moduleIds=${courseId}&language=${langSwitch(language)}`;
      const { data } = await axios.get(url);
      dispatch(UPDATE_LAST_COURSE_METADATA(data.courses[0]));
      dispatch(HANDLE_COURSE_STATUS({ key: 'isFetching', value: false }));
    } catch (err) {
      dispatch(HANDLE_COURSE_STATUS({ key: 'isFetching', value: false }));
      dispatch(HANDLE_COURSE_STATUS({ key: 'isError', value: true }));
      dispatch(HANDLE_COURSE_STATUS({ key: 'isDuplicated', value: false }));
    }
  };

export const save_collection = () =>
  async function (dispatch, getState) {
    try {
      const {
        collection: { data, courses }
      } = getState();

      dispatch(HANDLE_STATUS('saving'));
      const collectionIds = courses.map((obj) => obj.moduleId);
      const payload = { ...data, collectionIds };
      (!payload.partnersLogo || !payload.partnersLogo.length) && delete payload.partnersLogo;      
      delete payload.inProgress   
      delete payload.isNew;
      delete payload.isUpdated;  
      await axios.put(SAVE_CONTENT_METADATA_URL, payload);
      dispatch(HANDLE_STATUS('success'));
      setTimeout(() => {
        dispatch(HANDLE_STATUS('idle'));
      }, 3000);
    } catch (err) {
      dispatch(HANDLE_STATUS('error'));
      dispatch(HANDLE_SERVER_ERR(true));
    }
  };

export const save_new_collection = () =>
  async function (dispatch, getState) {
    try {
      const {
        collection: { data, courses }
      } = getState();
      dispatch(HANDLE_STATUS('saving'));
      const collectionIds = courses.map((obj) => obj.moduleId);
      const payload = { ...data, collectionIds };
      const { data: newCollectionData } = await axios.post(SAVE_CONTENT_METADATA_URL, payload);
      dispatch(SET_COLLECTION(newCollectionData));
      dispatch(HANDLE_STATUS('success'));
      setTimeout(() => {
        dispatch(HANDLE_STATUS('idle'));
      }, 3000);
      // replace in the browser address url the word "new" with the id of the newly created collection
      window.history.replaceState({}, '', `/collection/${newCollectionData.id}`);
    } catch (err) {
      dispatch(HANDLE_STATUS('error'));
      dispatch(HANDLE_SERVER_ERR(true));
    }
  };

export default collectionSlice.reducer;
